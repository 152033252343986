<template>
  <el-container style="height:100%">
    <el-header>
      <el-container>
        <el-aside width="210px" style="background-color: #409EFF;height:60px;overflow:hidden" class="text_center">
          <el-image :src="require('@/assets/img/logo.png')" style="width:143px;height:47px;" class="martp_5"></el-image>
        </el-aside>
        <main class="menu_toggle" @click.prevent="collapseChage">
          <i class="el-icon-s-fold" v-show="!collapse"></i>
          <i class="el-icon-s-unfold" v-show="collapse"></i>
        </main>
        <el-main style="padding:0;height:60px;overflow:hidden;min-width:600px">
          <el-menu :default-active="activeHeaderIndex" mode="horizontal" background-color="#409EFF" text-color="#fff" active-text-color="#ffd04b" @select="handleSelect">
            <el-menu-item v-for="(menu) in menus" :key="menu.Code" :index="menu.Code">{{menu.Name}}</el-menu-item>
          </el-menu>
        </el-main>
        <main class="el-main el-main-right dis_flex flex_x_end" style="padding: 0px;height:60px;overflow:hidden;min-width:280px">
          <ul class="el-menu--horizontal el-menu">
            <li class="el-menu-item padrt_10">
              <el-tooltip class="icon-rank" effect="dark" :content="fullscreen?`取消全屏`:`全屏`" placement="bottom" @click.native="handleFullScreen">
                <i class="el-icon-rank"></i>
              </el-tooltip>
            </li>
            <li class="el-menu-item padrt_10">
              <el-dropdown trigger="click" ref="entityDropDown" style="color: #fff" size="small" class="menu-item-after">
                <span>
                  {{entityName}}
                  <i class="el-icon-arrow-down" style="color: #fff;font-size:14px"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div class="pad_5">
                    <el-input prefix-icon="el-icon-search" placeholder="输入关键字进行搜索" v-model="filterText" size="mini" clearable></el-input>
                    <el-tree class="padtp_5" :expand-on-click-node="false" node-key="ID" :data="entityList" :auto-expand-parent="true" :default-expanded-keys="[1]" :props="defaultProps" @node-click="handleNodeClick" :filter-node-method="filterNode" ref="treeEntity">
                      <div slot-scope="{ data }">
                        <span class="font_14">{{ data.EntityName }}</span>
                        <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsStore">门店</el-tag>
                        <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsWarehouse">仓库</el-tag>
                      </div>
                    </el-tree>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li class="el-menu-item padrt_10">
              <el-dropdown trigger="click" @command="handleCommand" style="color: #fff" size="small">
                <span>
                  {{userName}}
                  <i class="el-icon-arrow-down" style="color: #fff;font-size:14px"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-switch-button" command="1">退出登录</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-edit-outline" command="2" divided>修改密码</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <!--<li class="el-menu-item padrt_20" @click="handleCommand">-->
            <!--<el-image style="width:30px;height:30px" :src="require('@/assets/img/quit.png')"></el-image>-->
            <!--</li>-->
          </ul>
        </main>
      </el-container>
    </el-header>
    <el-container class="content_box">
      <el-aside width="210px" :class="{'aside_collapse':collapse}">
        <el-scrollbar class="page-component__scroll">
          <el-menu :default-active="onRoutes" :unique-opened="true" :collapse="collapse" :router="true">
            <template v-for="(item) in asideMenus">
              <template v-if="item.Child">
                <el-submenu :index="item.Code" :key="item.Code">
                  <template slot="title">
                    <i :class="item.IconStyle"></i>
                    <span slot="title">{{ item.Name }}</span>
                  </template>
                  <template v-for="(subItem) in item.Child">
                    <el-submenu v-if="subItem.Child" :index="subItem.RouterPath" :key="subItem.Code">
                      <template slot="title">{{ subItem.Name }}</template>
                      <el-menu-item v-for="(threeItem) in subItem.Child" :key="threeItem.Code" :index="threeItem.RouterPath">{{ threeItem.Name }}</el-menu-item>
                    </el-submenu>
                    <el-menu-item v-else :index="subItem.RouterPath" :key="subItem.Code">{{ subItem.Name }}</el-menu-item>
                  </template>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item :index="item.RouterURL" :key="item.Code">
                  <i :class="item.IconStyle"></i>
                  <span slot="title">{{ item.Name }}</span>
                </el-menu-item>
              </template>
            </template>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-main class="content_right" style="padding:0px 0px;background: #f0f0f0;">
        <el-container>
          <el-header style="background-color: #ffffff;height: auto;border:1px solid #f0f0f0" v-show="this.$router.currentRoute.name != 'Home'">
            <draggable tag="span" :list="dynamicTags" v-bind="dragOptions">
              <el-tag style="margin:4px 0px 4px 5px;cursor:pointer" v-for="tag in dynamicTags" effect="dark" :key="tag.name" closable @close="tagClose(tag)" @click="tagClick(tag)" @dblclick.native="tabdbClick(tag)" :type="isActive(tag.fullPath)?'Primary':'info'">{{tag.name}}</el-tag>
            </draggable>
            <el-dropdown style="float:right;margin:4px 0px 4px 5px" size="small" split-button type="primary" @click="tabOtherCloseClick" @command="tabCloseClick">
              关闭其他标签页
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="right">关闭右侧标签页</el-dropdown-item>
                <el-dropdown-item command="left">关闭左侧标签页</el-dropdown-item>
                <el-dropdown-item command="all" divided>关闭所有标签页</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-header>
          <el-main class="content" style="padding:10px">
            <transition name="move" mode="out-in">
              <keep-alive :include="keepAliveInclude">
                <router-view v-if="refresh"></router-view>
              </keep-alive>
            </transition>
          </el-main>
        </el-container>
      </el-main>
    </el-container>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="500px">
      <span slot="title">
        <span class="font_18">修改密码</span>
        <span class="color_gray font_12 marlt_10">修改后将跳转登录页，需重新登录</span>
      </span>
      <div>
        <el-form size="small" ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px">
          <el-form-item label="原登录密码" prop="OldPassword">
            <el-input v-model="ruleForm.OldPassword" placeholder="请输入原登录密码" show-password clearable></el-input>
          </el-form-item>
          <el-form-item label="新的登录密码" prop="NewPassword">
            <el-input type="password" v-model="ruleForm.NewPassword" placeholder="请输入新的登录密码" show-password clearable></el-input>
          </el-form-item>
          <el-form-item label="确认新的登录密码" prop="NewPassword2">
            <el-input type="password" v-model="ruleForm.NewPassword2" placeholder="请输入确认新的登录密码" show-password clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleLogin" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import API from "@/api/account";
import axios from "axios";
import md5 from "js-md5";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import draggable from "vuedraggable";

var Enumerable = require("linq");
export default {
  components: {
    draggable,
  },
  data() {
    return {
      refresh: true,
      keepAliveInclude: [],
      activeHeaderIndex: "",
      dialogVisible: false,
      collapse: false,
      fullscreen: false,
      isdropdown: true,
      userName: "",
      entityName: "",
      menus: [],
      asideMenus: [],
      dynamicTags: [],
      entityList: [], //职务列表
      filterText: "",
      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
      ruleForm: {
        OldPassword: "",
        NewPassword: "",
        NewPassword2: "",
      },
      rules: {
        OldPassword: [
          { required: true, message: "请输入原登录密码", trigger: "blur" },
        ],
        NewPassword: [
          { required: true, message: "请输入新的登录密码", trigger: "blur" },
        ],
        NewPassword2: [
          {
            required: true,
            message: "请输入确认新的登录密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    $route(newValue) {
      this.setTags(newValue);
      this.parentPath(this.menus, newValue);
    },
    dynamicTags() {
      this.keepAliveInclude = Enumerable.from(this.dynamicTags)
        .select((val) => val.pathName)
        .toArray();
      localStorage.setItem("dynamic-tag", JSON.stringify(this.dynamicTags));
    },
    filterText(val) {
      this.$refs.treeEntity.filter(val);
    },
  },
  computed: {
    onRoutes() {
      return this.$route.path;
    },
    dragOptions() {
      return {
        animation: 200,
      };
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.EntityName.indexOf(value) !== -1;
    },
    // 侧边栏折叠
    collapseChage() {
      this.collapse = !this.collapse;
    },
    handleSelect(key) {
      this.activeHeaderIndex = key;
      this.asideMenus = Enumerable.from(this.menus)
        .where(function (i) {
          return i.Code == key;
        })
        .toArray()[0].Child;
    },

    tagClose(tag) {
      const tagIndex = this.dynamicTags.indexOf(tag);
      this.dynamicTags.splice(tagIndex, 1);
      if (
        this.$router.history.current.path == tag.fullPath &&
        this.dynamicTags.length > 0
      ) {
        this.$router.push(
          (this.dynamicTags[tagIndex]
            ? this.dynamicTags[tagIndex]
            : this.dynamicTags[tagIndex - 1]
          ).fullPath
        );
      }
      if (this.dynamicTags.length <= 0) {
        this.$router.push("/");
        // this.setTags(this.$router.currentRoute);
      }
    },
    tagClick(tag) {
      if (this.$router.history.current.path != tag.fullPath) {
        this.$router.push(tag.fullPath);
      }
    },
    tabdbClick(tag) {
      this.keepAliveInclude.splice(
        this.keepAliveInclude.indexOf(tag.pathName),
        1
      );
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
        this.keepAliveInclude = Enumerable.from(this.dynamicTags)
          .select((val) => val.pathName)
          .toArray();
      });
    },
    tabCloseClick(command) {
      var that = this;
      if (this.dynamicTags.length > 0) {
        var indexOfCurrentTag = this.dynamicTags.indexOf(
          Enumerable.from(this.dynamicTags)
            .where(function (i) {
              return i.fullPath == that.$route.fullPath;
            })
            .toArray()[0]
        );
        if (command == "right") {
          this.dynamicTags = Enumerable.from(this.dynamicTags)
            .where(function (val, i) {
              return i <= indexOfCurrentTag;
            })
            .toArray();
        } else if (command == "left") {
          this.dynamicTags = Enumerable.from(this.dynamicTags)
            .where(function (val, i) {
              return i >= indexOfCurrentTag;
            })
            .toArray();
        } else {
          this.dynamicTags = [];
          this.$router.push("/");
          this.setTags(this.$router.currentRoute);
        }
      }
    },
    tabOtherCloseClick() {
      var that = this;
      this.dynamicTags = Enumerable.from(this.dynamicTags)
        .where(function (i) {
          return i.fullPath == that.$route.fullPath;
        })
        .toArray();
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    // 退出
    handleCommand: function (val) {
      if (val == 1) {
        localStorage.removeItem("access-user");
        localStorage.setItem("dynamic-tag", "[]");
        this.$router.push("/login");
      } else {
        this.dialogVisible = true;
      }
    },
    // 设置标签
    setTags(route) {
      if (this.$router.currentRoute.name == "Home") {
        this.dynamicTags = [];
      } else {
        const isExist = this.dynamicTags.some((item) => {
          return item.fullPath == route.path;
        });
        if (!isExist) {
          this.dynamicTags.push({
            name: route.meta.title,
            fullPath: route.fullPath,
            pathName: route.matched[1].name,
            type: "Primary",
          });
        }
      }
      // const isExist = this.dynamicTags.some((item) => {
      //   return item.fullPath == route.path;
      // });
      // if (!isExist && this.$router.currentRoute.name != "Home") {
      //   this.dynamicTags.push({
      //     name: route.meta.title,
      //     fullPath: route.fullPath,
      //     pathName: route.matched[1].name,
      //     type: "Primary",
      //   });
      // }
    },
    isActive(fullPath) {
      return fullPath === this.$route.fullPath;
    },
    parentPath(menus, value) {
      var that = this;
      menus.forEach(function (item) {
        if (item.RouterPath == value.fullPath) {
          if (that.activeHeaderIndex != item.ParentCode.split("-")[0]) {
            that.activeHeaderIndex = item.ParentCode.split("-")[0];
            that.asideData();
          }
        }
        if (item.Child && item.Child.length > 0) {
          that.parentPath(item.Child, value);
        }
      });
    },
    asideData() {
      var that = this;
      this.asideMenus = Enumerable.from(this.menus)
        .where(function (i) {
          return i.Code == that.activeHeaderIndex;
        })
        .toArray()[0].Child;
    },
    entityData: function () {
      var that = this;
      API.getEntity().then((res) => {
        if (res.StateCode == 200) {
          that.entityList = res.Data;
        } else {
          this.$message.error({
            message: res.message,
            duration: 2000,
          });
        }
      });
    },

    // 切换门店
    handleNodeClick: function (val) {
      var that = this;
      var params = {
        EntityID: val.ID,
      };
      API.getSwitchEntity(params).then((res) => {
        if (res.StateCode == 200) {
          that.entityName = res.Data.EntityName;
          var accessuser = JSON.parse(localStorage.getItem("access-user"));
          accessuser.EntityName = res.Data.EntityName;
          accessuser.EntityID = val.ID;
          accessuser.IsStore = res.Data.IsStore;
          accessuser.IsWarehouse = res.Data.IsWarehouse;
          accessuser.AuthToken = res.Data.AuthToken;
          localStorage.setItem("access-user", JSON.stringify(accessuser));
          that.tabCloseClick();
          that.$refs.entityDropDown.visible = false;
        } else {
          this.$message.error({
            message: res.message,
            duration: 2000,
          });
        }
      });
    },
    // 修改密码
    handleLogin() {
      var that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (that.ruleForm.NewPassword != that.ruleForm.NewPassword2) {
            that.$message.error({
              message: "两次密码输入不一致，请重新输入",
              duration: 2000,
            });
            return false;
          }
          let para = Object.assign({}, that.ruleForm);
          var params = {
            OldPassword: md5(para.OldPassword),
            NewPassword: md5(para.NewPassword),
          };
          API.setPassword(params)
            .then(function (res) {
              if (res.StateCode == 200) {
                that.$message.success({
                  message: "密码修改成功",
                  duration: 2000,
                });
                (that.dialogVisible = false), that.$router.push("/login");
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.loading = false;
            });
        }
      });s
    },
  },
  mounted() {
    var that = this;
    that.userName = JSON.parse(
      localStorage.getItem("access-user")
    ).EmployeeName;
    that.entityName = JSON.parse(
      localStorage.getItem("access-user")
    ).EntityName;

    that.entityData();
    API.getSidebar().then((res) => {
      if (res.StateCode == 200) {
        this.menus = res.Data;
        this.setTags(this.$router.currentRoute);
        if (this.menus.length > 0) {
          if (this.$router.currentRoute.name == "Home") {
            this.activeHeaderIndex = this.menus[0].Code;
            this.asideMenus = this.menus[0].Child;
          } else {
            if (localStorage.getItem("dynamic-tag") == null) {
              localStorage.setItem("dynamic-tag", "[]");
            } else {
              this.dynamicTags = JSON.parse(
                localStorage.getItem("dynamic-tag")
              );
            }
            this.setTags(this.$router.currentRoute);
            this.parentPath(this.menus, this.$router.currentRoute);
          }
        }
      } else {
        this.$message.error({
          message: res.message,
          duration: 2000,
        });
      }
    });
  },
};
</script>

<style scoped lang="scss">
.menu_toggle {
  display: flex;
  align-items: center;
  margin: 0 10px;
  i {
    font-size: 20px;
    color: #fff;
  }
}

.el-header {
  padding: 0 0px;
  background-color: #409eff;
}

.content_box {
  height: calc(100% - 60px);

  .aside_collapse {
    width: 65px !important;
    overflow-x: hidden;
  }
  .content_right {
    height: 100%;
    .el-container {
      height: 100%;
    }
  }
}

.el-menu {
  border-right: solid 0px #e6e6e6;
}

.icon-rank {
  color: #fff;
  font-size: 25px;
  line-height: 60px;
  transform: rotate(45deg);
}

.el-main-right {
  height: max-content;
  .el-menu--horizontal {
    .el-menu-item {
      color: #fff !important;
      background: #409eff !important;
      padding: 0;
    }
  }
}

.el-tag--dark.el-tag--info {
  background-color: #c0c4cc;
  border-color: #c0c4cc;
}
.page-component__scroll {
  height: 100%;
}
.menu-item-after:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  right: -0px;
  top: 50%;
  transform: translateY(-40%);
  background-color: #dcdfe6;
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px;
}
</style>
